import React from 'react';
import AdminNav from "../../layout/AdminNav";

const Admin = () => {

  return (
    <div>
    <AdminNav />
    </div>
  );
};

export default Admin;